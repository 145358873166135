import List from "./List";
import Edit from "./Edit";
import Create from "./Create";
import VisitorIcon from "@material-ui/icons/People";

export default {
  list: List,
  edit: Edit,
  create: Create,
  icon: VisitorIcon,
};
