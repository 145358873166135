import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  Filter,
  ReferenceField,
  ReferenceArrayField,
  TextInput,
  SingleFieldList,
  ChipField,
  NumberField,
  DateField,
  ReferenceArrayInput, useRecordContext,
  ReferenceInput,
  SelectInput
} from "react-admin";
import * as PropTypes from "prop-types";

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
    <ReferenceInput
        label="Status"
        source="status_id"
        reference="classifiers-team-statuses"
        alwaysOn
    >
      <SelectInput label="Status" source="status_id" optionText="name" />
    </ReferenceInput>

    <SelectInput label="Reward" source="reward" choices={[
      { id: 'Winner', name: 'Winner' },
      { id: 'Karma', name: 'Karma' },
    ]} optionText="name"  alwaysOn />

  </Filter>
);

const RewardTextField = ({ source }) => {
  const record = useRecordContext();

  return (<span>{record["opportunity"] && record["opportunity"]["winner_team_id"] && record["id"] == record["opportunity"]["winner_team_id"] ? "Winner" : "Karma"}</span>);
};

export default (props) => {
  return (
    <List {...props} title="Teams" filters={<PostFilter />}>
      <Datagrid>

        <TextField source="name" />
        <TextField source="description" />
        <ReferenceField
            label="Call"
            source="opportunity_id"
            reference="opportunities"
        >
          <TextField source="title" />
        </ReferenceField >
        <ReferenceField
            label="Terms of profit"
          source="terms_of_profit_id"
          reference="classifiers-team-terms-of-profit"
        >
          <TextField source="name" />
        </ReferenceField >

        <ReferenceField
            label="Status"
            source="status_id"
            reference="classifiers-team-statuses"
        >
          <TextField source="name" />
        </ReferenceField >
        <TextField label="Reward" source="reward"/>
        {/*<RewardTextField*/}
        {/*    label="Reward"*/}
        {/*    source="reward"*/}
        {/*/>*/}

        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};
