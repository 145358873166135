import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  Filter,
  ReferenceField,
  ReferenceArrayField,
  TextInput,
  SingleFieldList,
  ChipField,
  NumberField, DateField, SelectArrayInput, ReferenceArrayInput
} from "react-admin";

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
  </Filter>
);

export default (props) => {
  return (
    <List {...props} title="Creations" filters={<PostFilter />}>
      <Datagrid>
        <TextField source="title" />
        <ReferenceField
          label="Purchase type"
          source="purchase_type_id"
          reference="classifiers-purchase-types"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Award type"
          source="award_type_id"
          reference="classifiers-award-types"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceArrayField
          label="Interest areas"
          source="interest_areas"
          reference="classifiers-interest-areas"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField
          label="Creation category"
          source="creation_tags"
          reference="classifiers-creation-tags"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <NumberField source="price" />
        <DateField
            source="created_at"
            label="Created at"
            sortable={false}
            locales="fr-FR"
        />
        <ReferenceField
            label="Status"
            source="status_id"
            reference="creation-statuses"
            link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};
