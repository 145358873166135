import * as React from "react";
import {
  DeleteButton,
  NumberInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  TitleForRecord,
  Toolbar,
  ImageInput,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  FileInput,
} from "react-admin";
import { Grid } from "@material-ui/core";
import GeneralInfoTab from "./tabs/GeneralInfo";
import StagesTab from "./tabs/Stages";
import TeamsTab from "./tabs/Teams";
import TasksTab from "./tabs/Tasks";

import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import { TabPanel } from "@material-ui/lab";

export default (props) => {
  const [activeTab, setActiveTab] = React.useState("General");

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const mainTab = {
    name: "General",
    component: <GeneralInfoTab {...props} />,
  };
  const tabs = [
   {
      name: "Stages",
      component: <StagesTab {...props} />,
    }, /*
    {
      name: "Tasks",
      component: <TasksTab {...props} />,
    },*/
    {
      name: "Teams",
      component: <TeamsTab {...props} />,
    },
  ];

  // material ui bug prevents conditional rendering inside tablist
  const tabListTabs = props.record.id ? (
    tabs.map((t) => <Tab label={t.name} value={t.name} />)
  ) : (
    <Tab label="" value="" disabled />
  );
  const tabPanelTabs = props.record.id ? (
    tabs.map((t) => (
      <TabPanel label={t.name} value={t.name}>
        {t.component}
      </TabPanel>
    ))
  ) : (
    <TabPanel label="" value="">
      {null}
    </TabPanel>
  );

  return (
    <div {...props}>
      <TitleForRecord
        title={props.record.title}
        record={props.record}
        defaultTitle={props.defaultTitle}
      />

      <TabContext value={activeTab}>
        <TabList onChange={handleChange} aria-label="Call">
          <Tab label={mainTab.name} value={mainTab.name} />
          {tabListTabs}
        </TabList>
        <TabPanel label={mainTab.name} value={mainTab.name}>
          {mainTab.component}
        </TabPanel>
        {tabPanelTabs}
      </TabContext>
    </div>
  );
};
