import * as React from "react";
import { Create } from "react-admin";
import Form from "./Form";
import { PostEditActions } from "../../../components/PostEditActions";

export default (props) => (
  <Create {...props} actions={PostEditActions(props)} title="FAQ sections / Create">
    <Form />
  </Create>
);
