import {
  CHECK_AUTH,
  CHECK_ERROR,
  GET_EMAIL,
  GET_NAME,
  GET_IDENTITY,
  GET_PERMISSIONS,
  LOGIN,
  LOGOUT,
  SAFE_LOGIN,
} from "./actions";
import { useRedirect } from "react-admin";
import { HttpError } from "react-admin";

const getErrorsMsg = (errorsObject) => {
  return Object.keys(errorsObject)
    .map((key) => {
      return `${key}: ${errorsObject[key].join(";\n ")} `;
    })
    .join(" \n");
};

const handleCatch = (e) => {
  const { status, headers, data } = e.response;

  if (status === 401 || status === 403) {
    console.log("status", status);
    return Promise.reject({ status, headers, data });
  } else {
    return Promise.reject(
      new HttpError(getErrorsMsg(data.errors), status, data)
    );
  }
};

const authProvider = (httpClient, params = {}) => {
  params = {
    routes: {
      login: "auth/admin-login",
      logout: "auth/logout",
      refresh: "auth/refresh",
      user: "auth/me",
    },
    accessTokenKey: "access_token",
    getAccessToken: (r) => r.access_token.token,
    refreshTokenKey: "refresh_token",
    getRefreshToken: (r) => r.refresh_token.token,
    getCredentials: ({ username, password }) => {
      return {
        email: username,
        password,
      };
    },
    getName: (u) => u.login,
    getEmail: (u) => u.email,
    getPermissions: (u) => Promise.resolve(u.roles),
    ...params,
  };

  let {
    routes,
    accessTokenKey,
    refreshTokenKey,
    getCredentials,
    getName,
    getEmail,
    getPermissions,
    getAccessToken,
    getRefreshToken,
  } = params;

  return {
    [LOGIN]: async ({ username, password }) => {
      let response = await httpClient.post(
        routes.login,
        getCredentials({ username, password })
      );

      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      localStorage.setItem(accessTokenKey, getAccessToken(response.data));
      localStorage.setItem(refreshTokenKey, getRefreshToken(response.data));

      return Promise.resolve();
    },
    [LOGOUT]: async () => {
      try {
        if (routes.logout) {
          await httpClient.post(routes.logout);
        }

        localStorage.removeItem(accessTokenKey);
        localStorage.removeItem(refreshTokenKey);
        return Promise.resolve();
      } catch (error) {}
    },
    [CHECK_AUTH]: async () => {
      try {
        return localStorage.getItem(accessTokenKey)
          ? Promise.resolve({
              data: true,
            })
          : Promise.reject();
      } catch (error) {
        console.log("auth error", error);
        localStorage.removeItem(accessTokenKey);
        return Promise.reject();
      }
    },
    [CHECK_ERROR]: async ({ status, ...rest }) => {
      console.log("status in checkerror", status);
      if (status === 401 || status === 403) {
        /**
         * Refresh token
         */
        if (routes.refresh) {
          try {
            let response = await httpClient.post(routes.refresh);
            console.log("refresh response", response);
            localStorage.setItem(accessTokenKey, getAccessToken(response.data));
          } catch (error) {
            localStorage.removeItem(accessTokenKey);
            localStorage.removeItem(refreshTokenKey);
            console.log("catch error", error.response);
            return Promise.reject();
          }
        }
      }
      return Promise.resolve();
    },
    [GET_NAME]: (user) => getName(user),
    [GET_EMAIL]: (user) => getEmail(user),
    [GET_IDENTITY]: async () => {
      try {
        let response = await httpClient.post(routes.user);
        const { data } = response;
        console.log("data", data);
        const { id, login } = data;
        return Promise.resolve({ id, fullName: login, avatar: null });
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          localStorage.removeItem(accessTokenKey);
          localStorage.removeItem(refreshTokenKey);
          if (routes.logout) {
            try {
              await httpClient.post(routes.logout);
            } catch (e) {
              return handleCatch(error);
            }
          }
        }
        return handleCatch(error);
      }
    },

    [GET_PERMISSIONS]: (user) => getPermissions(user),
  };
};

export default authProvider;
