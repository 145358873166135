import * as React from "react";
import {
  DeleteButton,
  NumberInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  TitleForRecord,
  Toolbar,
  ImageInput,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  FileInput,
} from "react-admin";

import GeneralInfoTab from "./tabs/GeneralInfo";
import CommentTab from "./tabs/PostComment";

import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import { TabPanel } from "@material-ui/lab";

export default (props) => {
  const [activeTab, setActiveTab] = React.useState("General");

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const mainTab = {
    name: "General",
    component: <GeneralInfoTab {...props} />,
  };
  const tabs = [
    {
      name: "Comment",
      component: <CommentTab {...props} />,
    },
  ];

  // material ui bug prevents conditional rendering inside tablist
  const tabListTabs = props.record.id ? (
      tabs.map((t) => <Tab label={t.name} value={t.name} />)
  ) : (
      <Tab label="" value="" disabled />
  );
  const tabPanelTabs = props.record.id ? (
      tabs.map((t) => (
          <TabPanel label={t.name} value={t.name}>
            {t.component}
          </TabPanel>
      ))
  ) : (
      <TabPanel label="" value="">
        {null}
      </TabPanel>
  );

  return (
      <div {...props}>
        <TitleForRecord
            title={props.record.title}
            record={props.record}
            defaultTitle={props.defaultTitle}
        />

        <TabContext value={activeTab}>
          <TabList onChange={handleChange} aria-label="Posts">
            <Tab label={mainTab.name} value={mainTab.name} />
            {tabListTabs}
          </TabList>
          <TabPanel label={mainTab.name} value={mainTab.name}>
            {mainTab.component}
          </TabPanel>
          {tabPanelTabs}
        </TabContext>
      </div>
  );
};
