import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  EditButton,
  DeleteButton,
  Filter,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput, DateField,
} from "react-admin";

import CustomFilter from "../../components/CustomFilter";
import TestFilter from "../../components/TestFilter";

const PostFilter = (props) => {
  return (
    <Filter {...props}>
    <TextInput label="Title" source="title" alwaysOn />
      {/*       <CustomFilter /> */}
      {/*     <TextInput label="Title" source="title" alwaysOn /> */}

{/*            to get filter to work on fields that are outside resource table (ex. interest areas for opportunity) we need to modify key to interest_areas.id (default is interest areas). For this to work add "SPACE id" as source, later this will be changed to interest_areas.id in dataProvider */}
      <ReferenceArrayInput
        label="Interest areas"
        source="interest_areas id"
        reference="classifiers-interest-areas"
      >
        <SelectArrayInput optionText="name" fullWidth />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Status"
        source="status_id"
        reference="classifiers-opportunity-statuses"
      >
        <SelectArrayInput optionText="name" fullWidth />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Call type"
        source="opportunity_type_id"
        reference="classifiers-opportunity-types"
      >
        <SelectArrayInput optionText="name" fullWidth />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Award type"
        source="award_type_id"
        reference="classifiers-award-types"
      >
        <SelectArrayInput optionText="name" fullWidth />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Tags"
        source="opportunity_tags id"
        reference="classifiers-opportunity-tags"
      >
        <SelectArrayInput optionText="name" fullWidth />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Creative fields"
        source="creative_fields id"
        reference="classifiers-creative-fields"
      >
        <SelectArrayInput optionText="name" fullWidth />
      </ReferenceArrayInput>
    </Filter>
  );
};

export default (props) => {
  return (
    <List {...props} title="Calls" filters={<PostFilter />}>
      <Datagrid>
        <TextField source="title" />
        <ReferenceField
          label="Status"
          source="status_id"
          reference="classifiers-opportunity-statuses"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Call type"
          source="opportunity_type_id"
          reference="classifiers-opportunity-types"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Award type"
          source="award_type_id"
          reference="classifiers-award-types"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceArrayField
          label="Interest areas"
          source="interest_areas"
          reference="classifiers-interest-areas"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField
          label="Call tags"
          source="opportunity_tags"
          reference="classifiers-opportunity-tags"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <NumberField source="budget" />
        <ReferenceField
            label="Call's creator"
            source="business_owner_id"
            reference="users"
            link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField
            label="Created"
            source="created_at"
            sortable={false}
            locales="fr-FR"
        />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};
