/* import axios from "axios";

const handleDownload = async (event, fileLink, fileName) => {
  console.log("event", event);
  console.log("fileLink", fileLink);
  console.log("fileName", fileName);
  event.preventDefault();
  const response = await axios({
    url: fileLink,
    method: "GET",
    responseType: "blob", // important
    mode: "cors",
  });

  if (response.status === 200) {
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
    return { success: true };
  }
}; */

export default ({ record, source }) => {
    console.log('record', record)
  if (record[source] && record[source].indexOf("blob:") == 0) {
    if (record.hasOwnProperty("rawFile")) {
      record.name = record.rawFile.name;
      let reader = new FileReader();
      reader.readAsDataURL(record.rawFile);
      reader.onloadend = () => {
        record.b64 = reader.result;
      };
    }

    return record.rawFile.name;
  } else {
    let url = `${process.env.REACT_APP_SWIFT_STORAGE_URL}${process.env.REACT_APP_SWIFT_CONTAINER_NAME}/${record.url}`;
    return (
      <a href={url} target="_blank">
        {record.name}
      </a>
    );
  }
};
