import * as React from "react";
import { useState } from "react";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import dataGridMakeStyles from "../../../components/MuiDataGridClasses";

import Typography from "@material-ui/core/Typography";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import DataGridCustomActions from "../../../components/DataGridCustomActions";
import PropTypes from "prop-types";

import {
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    SelectInput,
    Datagrid,
    TextField,
    NumberField,
    ListContextProvider,
    useQueryWithStore,
    Loading,
    useDataProvider,
    useVersion,
    useRefresh,
    Pagination,
    Confirm,
    NumberInput,
    ReferenceInput,
    ReferenceField,
    SelectField, useQuery,
} from "react-admin";
import keyBy from "lodash/keyBy";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    paperWidthSm: {
        overflowY: "visible",
        width: 600,
    },
    dialogContentRoot: {
        overflowY: "visible",
    },
});

const Member = (props) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const version = useVersion();

    const classes = useStyles();
    const dataGridClasses = dataGridMakeStyles();

    const [open, setOpen] = useState(false);
    const [dialogTitleText, setDialogTitleText] = useState("");
    const [dialogButtonText, setDialogButtonText] = useState("");
    const [serviceToEdit, setServiceToEdit] = useState(null);
    const [dialogButtonActionPath, setDialogButtonActionPath] = useState(null);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [rowToDelete, setRowToDelete] = useState();


    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const DialogContent = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }))(MuiDialogContent);

    const DialogAddServiceToolbar = (props) => {
        return (
            <Toolbar {...props}>
                <SaveButton
                    label={dialogButtonText}
                    onSave={async (data) => {
                        console.log("onsave data", data);
                        if (serviceToEdit) {
                            await dataProvider.update(dialogButtonActionPath, {
                                id: data.id,
                                data,
                            });
                        } else {
                            await dataProvider.create(dialogButtonActionPath, {
                                data,
                            });
                        }

                        setOpen(false);
                        setServiceToEdit(null);
                        setDialogButtonActionPath(null);
                        refresh();
                    }}
                    submitOnEnter={false}
                />
            </Toolbar>
        );
    };

    const { data, total, loading, error } = useQueryWithStore({
        type: "getList",
        resource: `/opportunities/${props.record.opportunity_id}/teams/${props.record.id}/roles`,
        payload: {
            //pagination: { page, perPage },
            sort: { field: "id", order: "ASC" },
            filter: {},
        },
    });


    if (loading) {
        return null;
    }
    if (error) {
        return <p>ERROR: {error}</p>;
    }

    const confirmDelete = async () => {
        await dataProvider.delete(dialogButtonActionPath, {
            id: rowToDelete,
        });
        refresh();
        setOpenConfirmation(false);
    };

    return (
        <>
            <ListContextProvider
                key={version}
                value={{
                    resource: `/opportunities/${props.record.opportunity_id}/teams/${props.record.id}/roles`,
                    basePath: `/opportunities/${props.record.opportunity_id}/teams/${props.record.id}/roles`,
                    data: keyBy(data, "id"),
                    ids: data.map(({ id }) => id),
                    currentSort: { field: "id", order: "ASC" },
                    selectedIds: [],
                }}
            >
                <Button
                    onClick={() => {
                        setDialogButtonText("Save");
                        setDialogTitleText("Add Role");
                        setDialogButtonActionPath(
                            `/opportunities/${props.record.opportunity_id}/teams/${props.record.id}/roles`
                        );
                        setOpen(true);
                    }}
                >
                    <span style={{ color: green[500] }}>Add role</span>
                </Button>
                <Datagrid classes={dataGridClasses}>
                    <TextField source="role.name" label="Role" sortable={false} />
                    <TextField source="member.name" label="Member" sortable={false} />
                    <TextField source="description"  label="Role responsibilities" sortable={false} />

                    <DataGridCustomActions
                        clone
                        label="Actions"
                        titleText="Update role"
                        buttonText="Update"
                        sortable={false}
                        setServiceToEdit={setServiceToEdit}
                        setDialogButtonActionPath={setDialogButtonActionPath}
                        setRowToDelete={setRowToDelete}
                        setOpenConfirmation={setOpenConfirmation}
                        setDialogButtonText={setDialogButtonText}
                        setOpen={setOpen}
                        setDialogTitleText={setDialogTitleText}
                    />
                </Datagrid>
                {/*         <Pagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          page={page}
          perPage={perPage}
          setPage={setPage}
          total={total}
        /> */}
            </ListContextProvider>

            <Dialog
                fullWidth
                onClose={() => {
                    setOpen(false);
                    setServiceToEdit(null);
                }}
                aria-labelledby="customized-dialog-title"
                open={open}
                classes={{ paperWidthSm: classes.paperWidthSm }}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={() => {
                        setOpen(false);
                        setServiceToEdit(null);
                    }}
                >
                    {dialogTitleText}
                </DialogTitle>
                <DialogContent
                    dividers
                    classes={{
                        root: classes.dialogContentRoot,
                    }}
                >
                    <SimpleForm
                        toolbar={<DialogAddServiceToolbar />}
                        initialValues={
                            serviceToEdit
                                ? {
                                    ...serviceToEdit,
                                    team_id: props.record.id ? parseInt(props.record.id) : null,
                                }
                                : {
                                    team_id: props.record.id ? parseInt(props.record.id) : null,
                                    member_id: null,
                                }
                        }
                    >
                        <Grid container spacing={1} style={{ width: "100%" }}>
                            <Grid item xs={12}>
                                <ReferenceInput
                                    source="role_id"
                                    reference="classifiers-opportunity-roles"
                                >
                                    <SelectInput
                                        optionText="name"
                                        fullWidth
                                        validate={required()}
                                    />
                                </ReferenceInput>
                            </Grid>
                            <Grid item xs={12}>

                                <ReferenceInput source="member_id" reference="users" allowEmpty>
                                    <SelectInput optionText="name" fullWidth />
                                </ReferenceInput>
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput label="Role responsibilities" source="description" fullWidth />
                            </Grid>
                        </Grid>
                    </SimpleForm>
                </DialogContent>
            </Dialog>

            <Confirm
                isOpen={openConfirmation}
                title="Delete record"
                content="Are you sure you want to delete this record?"
                onConfirm={confirmDelete}
                onClose={() => setOpenConfirmation(false)}
            />
        </>
    );
};

export default Member;
