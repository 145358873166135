import * as React from "react";
import { useState } from "react";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { green } from "@material-ui/core/colors";
import { FieldArray } from "react-final-form-arrays";
import { Field } from "react-final-form";

import {
  ReferenceField,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  Datagrid,
  DateField,
  FunctionField,
  FileInput,
  ListContextProvider,
  useQueryWithStore,
  Loading,
  useDataProvider,
  useVersion,
  useRefresh,
  Pagination,
  Confirm,
  EditButton,
  DeleteButton,
  TextField,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  SelectArrayInput,
} from "react-admin";
import keyBy from "lodash/keyBy";
import CustomFileField from "../../../components/CustomFileField";
import CustomDataGridFileField from "../../../components/CustomDataGridFileField";
import DataGridCustomActions from "../../../components/DataGridCustomActions";
import dataGridMakeStyles from "../../../components/MuiDataGridClasses";
import TeamRoles from "../../../components/TeamRoles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
});

const Teams = (props) => {
  console.log("teams props", props);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const version = useVersion();

  const classes = useStyles();
  const dataGridClasses = dataGridMakeStyles();

  const [open, setOpen] = useState(false);
  const [dialogTitleText, setDialogTitleText] = useState("");
  const [dialogButtonText, setDialogButtonText] = useState("");
  const [serviceToEdit, setServiceToEdit] = useState(null);
  const [dialogButtonActionPath, setDialogButtonActionPath] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [rowToDelete, setRowToDelete] = useState();

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogAddServiceToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton
          label={dialogButtonText}
          onSave={async (data) => {
            // get id from the object, since there is no input field on frontend for id
            if (serviceToEdit) {
              await dataProvider.update(dialogButtonActionPath, {
                id: data.id,
                data,
              });
            } else {
              await dataProvider.create(dialogButtonActionPath, {
                data,
              });
            }

            setOpen(false);
            setServiceToEdit(null);
            setDialogButtonActionPath(null);
            refresh();
          }}
          submitOnEnter={false}
        />
      </Toolbar>
    );
  };

  const { data, total, loading, error } = useQueryWithStore({
    type: "getList",
    resource: `/opportunities/${props.record.id}/teams`,
    payload: {
      //pagination: { page, perPage },
      sort: { field: "id", order: "ASC" },
      //filter: {},
    },
  });

  if (loading) {
    return null;
  }
  if (error) {
    return <p>ERROR: {error}</p>;
  }
  const confirmDelete = async () => {
    await dataProvider.delete(dialogButtonActionPath, {
      id: rowToDelete,
    });
    refresh();
    setOpenConfirmation(false);
  };
  console.log("propsrecord", props.record);

  return (
    <>
      <ListContextProvider
        key={version}
        value={{
          resource: `/opportunities/${props.record.id}/teams`,
          basePath: `/opportunities/${props.record.id}/teams`,
          data: keyBy(data, "id"),
          ids: data.map(({ id }) => id),
          currentSort: { field: "id", order: "ASC" },
          selectedIds: [],
        }}
      >
        <Button
          onClick={() => {
            setDialogButtonText("Save");
            setDialogTitleText("Add team");
            setDialogButtonActionPath(
              `/opportunities/${props.record.id}/teams`
            );
            setOpen(true);
          }}
        >
          <span style={{ color: green[500] }}>Add team</span>
        </Button>

        <Datagrid classes={dataGridClasses}>
          <TextField label="Name" source="name" />
          <TextField label="Description" source="description" />
          <TextField label="Terms of profit" source="terms_of_profit.name" />
          <TextField label="Visibility" source="visibility.name" />
          <DateField
            source="updated_at"
            label="Updated"
            sortable={false}
            locales="fr-FR"
          />
          <DataGridCustomActions
            label="Actions"
            titleText="Update team"
            buttonText="Update"
            sortable={false}
            setServiceToEdit={setServiceToEdit}
            setDialogButtonActionPath={setDialogButtonActionPath}
            setRowToDelete={setRowToDelete}
            setOpenConfirmation={setOpenConfirmation}
            setDialogButtonText={setDialogButtonText}
            setOpen={setOpen}
            setDialogTitleText={setDialogTitleText}
          />
        </Datagrid>
        {/*         <Pagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          page={page}
          perPage={perPage}
          setPage={setPage}
          total={total}
        /> */}
      </ListContextProvider>

      <Dialog
        onClose={() => {
          setOpen(false);
          setServiceToEdit(null);
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{ paperFullWidth: classes.paperFullWidth }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpen(false);
            setServiceToEdit(null);
          }}
        >
          {dialogTitleText}
        </DialogTitle>
        <DialogContent
          dividers
          classes={{
            root: classes.dialogContentRoot,
          }}
        >
          <SimpleForm
            toolbar={<DialogAddServiceToolbar />}
            initialValues={
              serviceToEdit
                ? {
                    ...serviceToEdit,
                    team_id: serviceToEdit ? serviceToEdit.id : null,
                  }
                : {
                    team_id: serviceToEdit ? serviceToEdit.id : null,
                  }
            }
          >
            <Grid container spacing={1} style={{ width: "100%" }}>
              <Grid item xs={12}>
                <TextInput
                  label="Name"
                  source="name"
                  validate={required()}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label="Description"
                  source="description"
                  validate={required()}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <ReferenceInput
                  source="terms_of_profit_id"
                  reference="classifiers-team-terms-of-profit"
                >
                  <SelectInput
                    optionText="name"
                    fullWidth
                    validate={required()}
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12}>
                <ReferenceInput
                  source="visibility_id"
                  reference="classifiers-team-visibility"
                >
                  <SelectInput
                    optionText="name"
                    fullWidth
                    validate={required()}
                  />
                </ReferenceInput>
              </Grid>
{/*               <Grid item xs={12}>
                <FieldArray
                  name="opportunity_roles"
                  label="Opportunity roles"
                  initialValue={props?.record?.opportunity_roles?.map((r) => ({
                    role_id: r,
                    member_id: null,
                  }))}
                >
                  {({ fields }) =>
                    fields.map((name, index) => {
                      console.log("name", name);
                      return (
                        <div key={name}>
                          <ReferenceField
                            link={false}
                            label="Role"
                            source={name}
                            reference="classifiers-opportunity-roles"
                          >
                            <FunctionField
                              label="Name"
                              render={(record) => {
                                return `${index + 1}. Team role "${
                                  record.name
                                }"`;
                              }}
                            />
                          </ReferenceField>
                          <div>
                            <Grid>
                              <ReferenceArrayInput
                                source={`${name}.member_id`}
                                label="Users"
                                reference="users"
                              >
                                <SelectArrayInput optionText="name" fullWidth />
                              </ReferenceArrayInput>
                            </Grid>
                          </div>
                        </div>
                      );
                    })
                  }
                </FieldArray>
              </Grid> */}

              <Grid container spacing={1} style={{ width: "100%" }}>
                <Grid item xs={12}>
                  {serviceToEdit ? (
                    <TeamRoles
                      opportunityId={props.record.id}
                      id={serviceToEdit.id}
                      endpoint="roles"
                    />
                  ) : (
                    <Typography style={{ whiteSpace: "normal" }} color="error">
                      Save team before adding roles!
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </SimpleForm>
        </DialogContent>
      </Dialog>
      <Confirm
        isOpen={openConfirmation}
        title="Delete record"
        content="Are you sure you want to delete this record?"
        onConfirm={confirmDelete}
        onClose={() => setOpenConfirmation(false)}
      />
    </>
  );
};

export default Teams;
