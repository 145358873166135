import * as React from "react";
import { useState } from "react";
import {
  BooleanInput,
  DeleteButton,
  NumberInput,
  required,
  number,
  regex,
  minValue,
  SaveButton,
  SimpleForm,
  TextInput,
  TitleForRecord,
  Toolbar,
  ReferenceArrayInput,
  SelectArrayInput,
  ImageInput,
  PasswordInput,
  ArrayInput,
  SimpleFormIterator,
  FileInput,
  ReferenceInput,
  SelectInput,
  useQuery,
  TextField,
  Labeled,
} from "react-admin";

import CustomImageField from "../../components/CustomImageField";
import CustomFileField from "../../components/CustomFileField";
import Editor from "../../components/Editor";
import { Grid } from "@material-ui/core";
import { Field, FormSpy } from "react-final-form";

export default (props) => {
  const CustomToolbar = (props) => {
    return (
      <Toolbar
        {...props}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <SaveButton
          disabled={props.pristine}
          transform={(data) => {
            data.password_confirmation = data.password;
            data.cover_id = data.cover ? data.cover.id : null;
            return data;
          }}
        />
        <DeleteButton undoable={false} />
      </Toolbar>
    );
  };

  const requiredBody = (value) => (value ? undefined : "Body text required");


  return (
    <React.Fragment>
      <TitleForRecord
        title={props.record.title}
        record={props.record}
        defaultTitle={props.defaultTitle}
      />
      <SimpleForm {...props} toolbar={<CustomToolbar />}>
        <Grid container spacing={1} style={{ width: "100%" }}>
          <Grid item xs={12}>
            <TextInput source="name" fullWidth validate={required()} />
          </Grid>
          <Grid item xs={12}>
            <TextInput source="description" fullWidth validate={required()} />
          </Grid>

          <Grid item xs={12}>
            <ReferenceArrayInput
              source="interest_areas"
              reference="classifiers-interest-areas"
            >
              <SelectArrayInput optionText="name" fullWidth />
            </ReferenceArrayInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </React.Fragment>
  );
};
