import * as React from "react";
import {Edit} from "react-admin";
import Form from "./Form";

import {PostEditActions} from "../../components/PostEditActions";

export default (props) => {
    return (
        <Edit {...props} actions={PostEditActions(props)} undoable={false} title="Creations / ">
            <Form/>
        </Edit>
    );
};
