export default ({ record, source }) => {
    if (record[source]) {
      console.log("record[source]", record[source]);
      if (Array.isArray(record[source])) {
        return record[source].map((s) => (
          <>
            <a
              href={`${process.env.REACT_APP_SWIFT_STORAGE_URL}${process.env.REACT_APP_SWIFT_CONTAINER_NAME}/${s.url}`}
            >
              {s.name}
            </a>
            <br></br>
          </>
        ));
      } else {
        return (
          <a
            href={`${process.env.REACT_APP_SWIFT_STORAGE_URL}${process.env.REACT_APP_SWIFT_CONTAINER_NAME}/${record[source].url}`}
          >
            {record[source].name}
          </a>
        );
      }
    }
    return null;
  };
