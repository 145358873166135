import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
} from "./actions";

import qs from "qs";
import { HttpError } from "react-admin";
import HTMLDecoderEncoder from "html-encoder-decoder";

const getErrorsMsg = (errorsObject) => {
  return Object.keys(errorsObject)
    .map((key) => {
      return `${key}: ${errorsObject[key].join(";\n ")} `;
    })
    .join(" \n");
};

const handleCatch = (e) => {
  const { status, headers, data } = e.response;

  if (status === 401 || status === 403) {
    return Promise.reject({ status, headers, data });
  } else {
    return Promise.reject(
      new HttpError(getErrorsMsg(data.errors), status, data)
    );
  }
};

const dataProvider = (httpClient) => {
  return {
    [GET_LIST]: async (resource, params) => {
      console.log("getlist", resource, params);
      const { pagination, sort, filter } = params;

      let query = {};
      const regex = new RegExp(" id$");
      if (filter && Object.keys(filter).length) {
        let newFilter = Object.keys(filter).reduce((acc, key, index) => {
          // to get filter to work on fields that are outside resource table (ex. interest areas for opportunity) we need to modify key to interest_areas.id (default is interest areas)
          let newKey = regex.test(key) ? key.replace(regex, ".id") : key;
          acc = Array.isArray(filter[key])
            ? [...acc, [newKey, "in", filter[key]]]
            : [
                ...acc,
                [
                  newKey,
                  newKey.match(/^(title|name)$/) ? "contains" : "=",
                  filter[key],
                ],
              ];
          acc = index < Object.keys(filter).length - 1 ? [...acc, "and"] : acc;
          return acc;
        }, []);
        console.log("getlist newfilter", newFilter);
        query = {
          ...query,
          filter: JSON.stringify(newFilter),
        };
      }

      if (pagination) {
        query = {
          ...query,
          page: pagination.page,
          per_page: pagination.perPage,
        };
      }

      if (sort && sort.length) {
        query = {
          ...query,
          sort: JSON.stringify(sort),
        };
      }

      try {
        let { data } = await httpClient.get(
          `${resource}?${qs.stringify(query, { arrayFormat: "repeat" })}`
        );
        //console.log('data',data)
        let dataWithDecodedName = data.data.map((d) => {
          let name = d.name
            ? { name: HTMLDecoderEncoder.decode(d.name) }
            : null;
          return { ...d, ...name };
        });

        let modifiedData = dataWithDecodedName.map((d) => {
          return Object.keys(d).reduce((acc, cur) => {
            acc[cur] =
              Array.isArray(d[cur]) && cur !== "files"
                ? d[cur].length
                  ? d[cur].map((v) => (v.id ? v.id : v))
                  : d[cur]
                : d[cur];
            return acc;
          }, {});
        });

        return {
          data: modifiedData,
          total: data.meta.total,
        };
      } catch (e) {
        console.log("error", e);
        return handleCatch(e);
      }
    },

    [GET_MANY]: async (resource, params) => {
      const { pagination, sort, filter, ids } = params;
      console.log("getmanu params", params);

      let query = {};
      if (filter) {
        query = {
          ...query,
          filter: JSON.stringify(filter),
        };
      }
      if (ids) {
        /*         let customFilter = ids
                  .map((idObj, index) => Number.isNaN(idObj) ? [
                    ["id", "eq", idObj.id.toString()],
                    index + 1 < ids.length ? "or" : false,
                  ] :
                    [
                      ["id", "eq", idObj.toString()],
                      index + 1 < ids.length ? "or" : false,
                    ]
                  )
                  .flat()
                  .filter(Boolean); */

        let customFilter = [
          "id",
          "in",
          ids.map((i) => (Number.isNaN(i) ? i.id : i)),
        ];

        query = {
          ...query,
          filter: JSON.stringify(customFilter),
        };
      }

      if (pagination) {
        query = {
          ...query,
          page: pagination.page,
          per_page: pagination.per_page,
        };
      }

      if (sort && sort.length) {
        query = {
          ...query,
          sort: JSON.stringify(sort),
        };
      }

      try {
        console.log("getmanu query", query);
        const { data } = await httpClient.get(
          `${resource}?${qs.stringify(query, { arrayFormat: "repeat" })}`
        );
        console.log("getmanu data", data);
        return {
          data: data.data,
        };
      } catch (e) {
        return handleCatch(e);
      }
    },

    [GET_ONE]: async (resource, params) => {
      try {
        const { data } = await httpClient.get(`${resource}/${params.id}`);
        //data.data.name = HTMLDecoderEncoder.decode(data.data.name);
        console.log("getonedata", data);

        // due to changes to db change array data from array of objects to array of ids
        let modifiedData = Object.keys(data.data).reduce((acc, cur) => {
          acc[cur] =
            Array.isArray(data.data[cur]) && cur !== "files"
              ? data.data[cur].length
                ? data.data[cur].map((v) => (v.id ? v.id : v))
                : data.data[cur]
              : data.data[cur];
          return acc;
        }, {});
        console.log("modified getone", modifiedData);

        return {
          data: modifiedData,
        };
      } catch (e) {
        return handleCatch(e);
      }
    },

    [CREATE]: async (resource, params) => {
      try {
        console.log("POST PAYLOAD:", params.data);
        const { data } = await httpClient.post(resource, params.data);
        return {
          data: data.data,
        };
      } catch (e) {
        return handleCatch(e);
      }
    },

    [UPDATE]: async (resource, params) => {
      try {
        console.log("PUT PAYLOAD:", params.data);
        const { data } = await httpClient.put(
          `${resource}/${params.id}`,
          params.data
        );
        return {
          data: data.data,
        };
      } catch (e) {
        return handleCatch(e);
      }
    },

    [UPDATE_MANY]: (resource, params) =>
      Promise.all(
        params.ids.map((id) => httpClient.put(`${resource}/${id}`, params.data))
      ).then(() => Promise.resolve()),

    [DELETE]: async (resource, params) => {
      console.log("delete resource", resource);
      console.log("delete params", params);
      try {
        const { data } = await httpClient.delete(`${resource}/${params.id}`);
        console.log("delete data", data);
        return {
          data: data.data,
        };
      } catch (e) {
        console.log("delete catch", e);
        return handleCatch(e);
      }
    },

    [DELETE_MANY]: async (resource, params) => {
      const query = {
        filter: JSON.stringify({ id: params.ids }),
      };
      try {
        const { data } = await httpClient.delete(
          `${resource}?${JSON.stringify(query)}`
        );
        return {
          data: data.data,
        };
      } catch (e) {
        return handleCatch(e);
      }
    },
  };
};

export default dataProvider;
