import * as React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const dataGridMakeStyles = makeStyles((theme) => ({
  headerCell: {
    maxWidth: "60px",
    textAlign: "left",
    "&:last-child": {
      textAlign: "right",
    },
  },
  rowCell: {
    maxWidth: "60px",
    textAlign: "left",
    "&:last-child": {
      textAlign: "right",
    },
  },
  table: {
    width: "100%",
    "& .MuiTableCell-sizeSmall": {
      padding: "6px 24px 6px 16px",
      maxWidth: "60px"
    }
  },
}));

export default dataGridMakeStyles;
