import * as React from "react";
import { useState } from "react";
import {
  BooleanInput,
  DeleteButton,
  NumberInput,
  required,
  regex,
  SaveButton,
  SimpleForm,
  TextInput,
  TitleForRecord,
  Toolbar,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  ReferenceInput,
  ImageInput,
  PasswordInput,
  ArrayInput,
  SimpleFormIterator,
  minLength,
  maxLength,
  useQuery,
} from "react-admin";

import CustomImageField from "../../components/CustomImageField";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useIteratorStyle = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  form: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "& *": {
      width: "100%",
    },
  },
  line: {
    border: 0,
    display: "flex",
    width: "100%",
  },
}));

const validateArray = (values) => {
  if (values) {
    const error = values.map((v) => {
      if (v === undefined) {
        return true;
      } else if (!v["website_name"] || !v["url_link"]) {
        return true;
      } else {
        return false;
      }
    });
    return error.some((v) => v === true) ? " " : undefined;
  }
};

export default (props) => {
  const iteratorClasses = useIteratorStyle();
  const CustomToolbar = (props) => {
    return (
      <Toolbar
        {...props}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <SaveButton
          disabled={props.pristine}
          transform={(data) => {
            if (data.password) {
              data.password_confirmation = data.password;
            }
            const { profile_types, ...dataWithoutProfileTypes } = data;
            return dataWithoutProfileTypes;
          }}
        />
        <DeleteButton undoable={false} />
      </Toolbar>
    );
  };

  const { data, loading, error } = useQuery({
    type: "getMany",
    resource: "classifiers-profile-types",
    payload: { ids: props.record?.profile_types },
  });

  let isCreator = props.record?.profile_types?.reduce((acc, cur) => {
    if (cur === data?.filter(f => f.name === "Creator")?.[0]?.id) {
      acc = true;
      return acc;
    }
    return acc;
  }, false);

  return (
    <React.Fragment>
      <TitleForRecord
        title={props.record.name}
        record={props.record}
        defaultTitle={props.defaultTitle}
      />
      <SimpleForm {...props} toolbar={<CustomToolbar />}>
        <Grid container spacing={1} style={{ width: "100%" }}>
          <Grid item xs={12}>
            <ImageInput source="cover_image" label="Cover image">
              <CustomImageField source="link"></CustomImageField>
            </ImageInput>
          </Grid>
          <Grid item xs={12}>
            <ImageInput source="profile_image" label="Profile image">
              <CustomImageField source="link"></CustomImageField>
            </ImageInput>
          </Grid>
          {isCreator ? (
            <Grid item xs={12}>
              <BooleanInput source="is_master_creator" fullWidth />
            </Grid>
          ) : null}
{/*           {isCreator ? (
            <Grid item xs={12}>
              <BooleanInput source="is_premium" fullWidth />
            </Grid>
          ) : null} */}
          <Grid item xs={12}>
            <TextInput source="name" label="Business name / Nickname" fullWidth validate={required()} />
          </Grid>

          <Grid item xs={12}>
            <TextInput source="first_name" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextInput source="last_name" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextInput source="email" fullWidth validate={required()} />
          </Grid>
          <Grid item xs={12}>
            <TextInput source="biography" fullWidth multiline />
          </Grid>
          <Grid item xs={12}>
            <ReferenceInput source="country_id" reference="countries" fullWidth>
              <SelectInput optionText="name" fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12}>
            <PasswordInput
              source="password"
              initiallyVisible
              validate={[
                //required(),
                regex(
                  //new RegExp("[A-Za-z0-9!@#$%^&*]"),
                  new RegExp("^[ -~]+$"),
                  "Only printing ASCII characters are allowed"
                ),
                minLength(8, "Minimum password length is 8 characters"),
                maxLength(20, "Maximum password length is 20 characters"),
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <ReferenceArrayInput
              source="interest_areas"
              reference="classifiers-interest-areas"
              fullWidth
            >
              <SelectArrayInput optionText="name" fullWidth />
            </ReferenceArrayInput>
          </Grid>
          <Grid item xs={12}>
            <ReferenceArrayInput
              source="skills"
              reference="classifiers-skills"
              fullWidth
            >
              <SelectArrayInput optionText="name" fullWidth />
            </ReferenceArrayInput>
          </Grid>
          <Grid item xs={12}>
            <ArrayInput source="online_presences" validate={validateArray}>
              <SimpleFormIterator classes={iteratorClasses}>
                <TextInput
                  source="website_name"
                  label="Website name"
                  fullWidth
                  validate={required()}
                />
                <TextInput
                  source="url_link"
                  label="Link"
                  fullWidth
                  validate={required()}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>

          <Grid item xs={12}>
            <NumberInput source="cq" label="CQ" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <NumberInput source="wallet" fullWidth />
          </Grid>
        </Grid>
      </SimpleForm>
    </React.Fragment>
  );
};
