const CustomImageField = ({ record, source }) => {
  console.log('record img', record)
  console.log('source img', source)
  if (record[source] && record[source].indexOf("blob:") === 0) {
    if (record.hasOwnProperty("rawFile")) {
      record.name = record.rawFile.name;
      let reader = new FileReader();
      reader.readAsDataURL(record.rawFile);
      reader.onloadend = () => {
        record.b64 = reader.result;
      };
    }

    return <img src={record[source]} height="300" />;
  } else {
    return (
      <img
        alt=""
        src={`${process.env.REACT_APP_SWIFT_STORAGE_URL}${process.env.REACT_APP_SWIFT_CONTAINER_NAME}/${record[source]}`}
        height="300"
      />
    );
  }
};

export default CustomImageField;
