import * as React from "react";
import FileCopy from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";

import { useDataProvider, useRefresh } from "react-admin";

export default (props) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  return (
    <>
      <Button
        label="Delete"
        startIcon={<DeleteIcon style={{ fill: "#f44336" }} />}
        onClick={() => {
          props.setDialogButtonActionPath(props.basePath);
          props.setRowToDelete(props.record.id);
          props.setOpenConfirmation(true);
        }}
      />
      <Button
        label="Edit"
        startIcon={<EditIcon style={{ fill: "#3f51b5" }} />}
        onClick={() => {
          props.setServiceToEdit(props.record);
          props.setDialogButtonText(props.buttonText);
          props.setDialogTitleText(props.titleText);
          props.setDialogButtonActionPath(props.basePath);
          props.setOpen(true);
        }}
      />
      {props.clone ? (
        <Button
          label="Copy"
          startIcon={<FileCopy style={{ fill: "#3f51b5" }} />}
          onClick={async () => {
            let { id, ...dataToCopy } = props.record;
            await dataProvider.create(props.basePath, {
              data: dataToCopy,
            });
            refresh();
          }}
        />
      ) : null}
    </>
  );
};
