import * as React from "react";
import {
    ReferenceInput,
    SelectInput,
    Create,
    Edit,
    SimpleForm,
    NumberInput,
    TextInput,
    DateInput,
    ReferenceManyField,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    useDataProvider, useQueryWithStore, Toolbar, SaveButton, DeleteButton, TitleForRecord, useRefresh
} from 'react-admin';
import {Grid} from "@material-ui/core";
import {useEffect, useState} from "react";

const Settings = (props) => {
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const { data, total, loading, error } = useQueryWithStore({
        type: "getList",
        resource: `/opportunities/${props.record.opportunity_id}/teams/${props.record.id}/roles`,
        payload: {
            //pagination: { page, perPage },
            sort: { field: "id", order: "ASC" },
            filter: {},
        },
    });

    if (loading) {
        return null;
    }

    if (error) {
        return <p>ERROR: {error}</p>;
    }

    console.log("data.record", data);

    console.log("props.record", props.record);

    const CustomToolbar = (props) => {
        return (
            <Toolbar
                {...props}
                style={{display: "flex", justifyContent: "space-between"}}
            >
                <SaveButton
                    onSave={async (data) => {
                        console.log("onsave data", data);
                        let param = [];
                        data.amount.forEach(function (value, i) {
                            if(value !== null && typeof (value) !== "undefined"){
                                let val = {"id": i, "amount" : value};
                                param.push(val);
                            }
                        });
                        console.log("param", param);

                        dataProvider.update(`/admin-teams/budget`, {
                                id: data.id,
                                data: {"data":param},
                        }).then(response => {
                            console.log(response);
                            refresh();

                        })
                            .catch(error => {
                                console.log(error);
                                refresh();
                            });



                    }}
                />
                {/*DeleteButton undoable={false} />*/}
            </Toolbar>
        );
    };
    const validateAmountCreation = (value, allValues) => {
        let sum = 0;
        let last = 0;
        for (const i in allValues.amount) {
            let val = parseInt(allValues.amount[i]);
            if (val > 0) {
                sum = sum + val;
                last = i;
            }

        }
        console.log("sum ", sum);
        if (sum > props.record.opportunity.budget) {
            let st = "amount[" + last + "]";
            console.log(st);
            return 'Total value must be less than or equal to ' + props.record.opportunity.budget;
        }
        /*if (!values.firstName) {
            errors.firstName = 'The firstName is required';
        }*/

        return undefined;
    };
    return (
        <React.Fragment>
            <TitleForRecord
                //title={props.record.name}
                record={props.record}
                defaultTitle={props.defaultTitle}
            />
            <SimpleForm {...props} toolbar={<CustomToolbar/>}>
                <Grid container spacing={1} style={{width: "100%"}}>
                    <Grid item xs={12}>
                        <label>Terms of profit split - Budget: {props.record.opportunity.budget} EUR</label>
                        <ReferenceInput
                            source="terms_of_profit_id"
                            reference="classifiers-team-terms-of-profit"
                        >
                            <SelectInput
                                optionText="name"
                                fullWidth
                                validate={required()}
                                disabled
                            />
                        </ReferenceInput>

                    </Grid>
                    {Object.entries(data).map(([id, item]) => {
                        let name = "opportunity_roles[" + id + "][amount]";
                        console.log(item);
                        let help = "out of " + props.record.opportunity.budget + "EUR";
                        if ("amount" in props.record) {
                            props.record.amount[item.id] = item.amount != null ? item.amount : 0;
                        } else {
                            props.record.amount = [];
                            props.record.amount[item.id] = item.amount != null ? item.amount : 0;
                        }


                        return (
                            <Grid item xs={12}>
                                <label>{1 + parseInt(id)}. {item.role?.name} - {item.member?.name} </label>
                                <NumberInput
                                    validate={[required(), minValue(0), maxValue(props.record.opportunity.budget), validateAmountCreation]}
                                    min={0} max={props.record.opportunity.budget} label="" helperText={help}
                                    source={"amount[" + item.id + "]"} fullWidth/>
                            </Grid>
                        );

                    })}
                </Grid>
            </SimpleForm>
        </React.Fragment>
    );
}

export default Settings;