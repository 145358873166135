import EditorJs from "react-editor-js";
import Paragraph from "editorjs-paragraph-with-alignment";

import Embed from "@editorjs/embed";
//import Paragraph from "@editorjs/paragraph";
import LinkTool from "@editorjs/link";
import Image from "@editorjs/image";
//import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
//import Quote from "@editorjs/quote";
//import Marker from "@editorjs/marker";
//import Delimiter from "@editorjs/delimiter";
//import InlineCode from "@editorjs/inline-code";
import SimpleImage from "@editorjs/simple-image";
import http from "../http";
import { useRef, useEffect } from "react";
import { Field } from "react-final-form";

import { Grid, Button, Typography } from "@material-ui/core";

// Convert file to base64 string
export const fileToBase64 = (file) => {
  return new Promise((resolve) => {
    var reader = new FileReader();
    // Read file content on file loaded event
    reader.onload = function (event) {
      resolve(event.target.result);
    };

    // Convert data to base64
    reader.readAsDataURL(file);
  });
};
// Example call:

const EDITOR_JS_TOOLS = {
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
  },
  header: {
    class: Header,
    config: {
      inlineToolbar: ["link", "marker", "bold", "italic"],
    },
  },
  image: {
    class: Image,
    config: {
      uploader: {
        /**
         * Upload file to the server and return an uploaded image data
         * @param {File} file - file selected from the device or pasted by drag-n-drop
         * @return {Promise.<{success, file: {url}}>}
         */
        async uploadByFile(file) {
          // your own uploading logic here

          let b64 = await fileToBase64(file);

          return http
            .post("files", {
              name: file.name,
              b64: b64,
            })
            .then((response) => {
              return {
                success: 1,
                file: {
                  url: `${process.env.REACT_APP_SWIFT_STORAGE_URL}${process.env.REACT_APP_SWIFT_CONTAINER_NAME}/${response.data.data.link}`,
                },
              };
            })
            .catch((error) => {
              return {
                success: 0,
              };
            });
        },

        /**
         * Send URL-string to the server. Backend should load image by this URL and return an uploaded image data
         * @param {string} url - pasted image URL
         * @return {Promise.<{success, file: {url}}>}
         */
      },
    },
  },
};

const Editor = (props) => {
  console.log('editor props.record', props.record)
  const editorRef = useRef(null);

  useEffect(() => { }, [editorRef]);

  const addBlock = (type, data) => {
    const index = editorRef.current.blocks.getBlocksCount() + 1;
    editorRef.current.blocks.insert(type, data, undefined, index);
  };

  const Error = ({ name }) => (
    <Field
      name={name}
      subscription={{ touched: true, error: true }}
      render={({ meta: { touched, error } }) =>
        touched && error ? (
          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
            Body text required
          </p>
        ) : null
      }
    />
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Error name={props.name} />
        <EditorJs
          data={props.record ? JSON.parse(props.record) : null}
          instanceRef={(instance) => (editorRef.current = instance)}
          tools={EDITOR_JS_TOOLS}
          onReady={async () => {
            if (!props.value) {
              editorRef.current.blocks.insert("header", {
                text: "Title Text",
                level: 2,
              });

              editorRef.current.blocks.insert("paragraph", {
                text: "Body Text",
                alignment: "left",
              });

              editorRef.current.blocks.insert(
                "paragraph",
                {
                  text: "Body Text2",
                  alignment: "left",
                },
                undefined,
                editorRef.current.blocks.getBlocksCount() + 1
              );
            }
          }}
          onChange={async (data) => {
            let result = await data.saver.save();
            props.onChange(JSON.stringify(result));
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Button onClick={() => addBlock("image", undefined)}>Image</Button>
        <Button onClick={() => addBlock("paragraph", { text: "Text" })}>
          Text
        </Button>
        <Button onClick={() => addBlock("paragraph", { text: "Insert link" })}>
          Embed
        </Button>
      </Grid>
    </Grid>
  );
};

export default Editor;