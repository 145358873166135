import * as React from "react";
import { useState } from "react";
import {
  BooleanInput,
  DeleteButton,
  NumberInput,
  required,
  number,
  regex,
  minValue,
  SaveButton,
  SimpleForm,
  TextInput,
  TitleForRecord,
  Toolbar,
  ReferenceArrayInput,
  SelectArrayInput,
  ImageInput,
  PasswordInput,
  ArrayInput,
  SimpleFormIterator,
  FileInput,
  ReferenceInput,
  SelectInput,
  useQuery,
  TextField,
  Labeled,
} from "react-admin";

import CustomImageField from "../../components/CustomImageField";
import CustomFileField from "../../components/CustomFileField";
import Editor from "../../components/Editor";
import { Grid } from "@material-ui/core";
import { Field, FormSpy } from "react-final-form";

export default (props) => {
  const CustomToolbar = (props) => {
    return (
      <Toolbar
        {...props}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <SaveButton
          disabled={props.pristine}
          transform={(data) => {
            data.password_confirmation = data.password;
            data.cover_id = data.cover ? data.cover.id : null;
            return data;
          }}
        />
        <DeleteButton undoable={false} />
      </Toolbar>
    );
  };

  const requiredBody = (value) => (value ? undefined : "Body text required");

  const statusesQuery = useQuery({
    type: "getList",
    resource: `creation-statuses`,
    payload: { filter: { id: props.record?.id } },
  });

  return (
    <React.Fragment>
      <TitleForRecord
        title={props.record.name}
        record={props.record}
        defaultTitle={props.defaultTitle}
      />
      <SimpleForm {...props} toolbar={<CustomToolbar />}>
        <Grid container spacing={1} style={{ width: "100%" }}>
          <Grid item xs={6}>
            <Labeled label="Current status">
              <TextField source="status.name" fullWidth />
            </Labeled>
            <Labeled label="Possible transitions">
              <TextField source="status.description" fullWidth />
            </Labeled>
          </Grid>
          {statusesQuery?.data ? (
            <Grid item xs={6}>
              <SelectInput
                source="status_id"
                fullWidth
                allowEmpty={true}
                choices={statusesQuery.data}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextInput source="title" fullWidth validate={required()} />
          </Grid>
          <Grid item xs={12}>
            <Field name="body" validate={requiredBody}>
              {(fieldProps) => {
                return (
                  <div>
                    <Editor {...fieldProps.input} record={props.record.body} />
                  </div>
                );
              }}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <ImageInput source="cover" label="Cover">
              <CustomImageField source="link"></CustomImageField>
            </ImageInput>
          </Grid>
          {/*           <Grid item xs={12}>
            <TextInput
              source="opportunity_name"
              fullWidth
            />
          </Grid> */}
          {/*           <Grid item xs={12}>
            <ReferenceArrayInput
              source="opportunity_category"
              reference="classifiers-opportunity-categories"
            >
              <SelectArrayInput optionText="name" fullWidth />
            </ReferenceArrayInput>
          </Grid> */}
          <Grid item xs={12}>
            <ReferenceArrayInput
              source="creative_fields"
              reference="classifiers-creative-fields"
            >
              <SelectArrayInput optionText="name" fullWidth />
            </ReferenceArrayInput>
          </Grid>
          <Grid item xs={12}>
            <ReferenceArrayInput
              source="interest_areas"
              reference="classifiers-interest-areas"
            >
              <SelectArrayInput optionText="name" fullWidth />
            </ReferenceArrayInput>
          </Grid>
          <Grid item xs={12}>
            <ReferenceArrayInput
              label="Creation category"
              source="creation_tags"
              reference="classifiers-creation-tags"
            >
              <SelectArrayInput optionText="name" fullWidth />
            </ReferenceArrayInput>
          </Grid>
          <Grid item xs={12}>
            <ReferenceInput
              source="purchase_type_id"
              reference="classifiers-purchase-types"
            >
              <SelectInput optionText="name" fullWidth />
            </ReferenceInput>
          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*  <ReferenceInput*/}
          {/*    source="award_type_id"*/}
          {/*    reference="classifiers-award-types"*/}
          {/*  >*/}
          {/*    <SelectInput optionText="name" fullWidth />*/}
          {/*  </ReferenceInput>*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <NumberInput
              source="price"
              fullWidth
              min={0}
              validate={[
                required(),
                number(),
                regex(new RegExp("[0-9]"), "Only numbers are allowed"),
                minValue(0, "Price can not be lower than 0"),
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <FileInput
              multiple
              fullWidth
              source="files"
              label="Files"
              //accept="application/pdf"
            >
              <CustomFileField multiple source="link" />
            </FileInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </React.Fragment>
  );
};
