import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;
const accessTokenKey = "access_token";
const refreshTokenKey = "refresh_token";
const getAccessToken = (r) => r.access_token.token;
const getRefreshToken = (r) => r.refresh_token.token;

const http = axios.create({
  baseURL,
  withCredentials: true,
  mode: "cors",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
    "Access-Control-Allow-Origin":
      "http://localhost:9999, http://localhost:8081, http://localhost:8082, http://localhost:3000, http://localhost:4000, http://localhost:5000, http://dev.wecreators.com, https://dev.wecreators.com, https://dev-admin.wecreators.com, http://dev-admin.wecreators.com, https://admin.wecreators.com, http://admin.wecreators.com",
  },
});

console.log('http', http)
console.log('process.env.REACT_APP_API_URL',process.env.REACT_APP_API_URL )

http.interceptors.request.use(
  function (config) {
    if (config.url === "auth/refresh") {
      console.log("inside refresh");
      let token = `Bearer ${localStorage.getItem(refreshTokenKey)}`;
      if (token) {
        config.headers.Authorization = token;
        return config;
      }
    } else {
      let token = `Bearer ${localStorage.getItem(accessTokenKey)}`;
      if (token) {
        config.headers.Authorization = token;
        return config;
      }
    }
  },
  function (error) {
    // Do something with request error
    console.log("request error", error);
    return Promise.reject(error);
  }
);

//http.interceptors.response.use(
//  function (config) {
//    /*     console.log("response", config.config.url);
//    if (config.config.url === "auth/refresh") {
//      localStorage.setItem(storageKey, getToken(config.data));
//    } */
//    return config;
//  },
//  function (error) {
//    // Do something with request error
//    const { status, headers, data } = error.response;
//    return Promise.reject({ status, headers, data });
//  }
//);

export default http;
