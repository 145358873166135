import * as React from "react";
import {
  DeleteButton,
  NumberInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  TitleForRecord,
  Toolbar,
  ImageInput,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  FileInput,
  FileField,
  DateInput,
  useQuery,
  TextField,
  Labeled
} from "react-admin";
import { Grid } from "@material-ui/core";

import CustomImageField from "../../../components/CustomImageField";
import CustomFileField from "../../../components/CustomFileField";
import { Field, FormSpy } from "react-final-form";
import Editor from "../../../components/Editor";
import moment from "moment";

export default (props) => {
  console.log("props", props);

  const statusesQuery = useQuery({
    type: "getList",
    resource: `opportunity-statuses`,
    //payload: { filter: ["opportunity_id", "=", props.record?.id] },
    payload: { filter: { "id": props.record?.id } },
  });


  const stagesQuery = useQuery({
    type: "getList",
    resource: `opportunity-stages`,
    payload: { filter: { "opportunity_id": props.record?.id } },
  });

  const CustomToolbar = (props) => {
    return (
      <Toolbar
        {...props}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <SaveButton
          disabled={props.pristine}
          transform={(data) => {
            data.cover_id = data.cover ? data.cover.id : null;
            data.start_date = moment(data.start_date).format("yyyy-MM-DD");
            data.end_date = moment(data.end_date).format("yyyy-MM-DD");
            data.stages = stagesQuery.data;
            return data;
          }}
        />
        <DeleteButton undoable={false} />
      </Toolbar>
    );
  };

  const requiredBody = (value) => (value ? undefined : "Body text required");

  const validateDateCreation = (values) => {
    const errors = {};
    if (new Date(values.start_date) > new Date(values.end_date)) {
      errors.end_date = "End date should be greater than Start date";
    }
    return errors
  };
  return (
    <React.Fragment>
      <TitleForRecord
        //title={props.record.name}
        record={props.record}
        defaultTitle={props.defaultTitle}
      />
      <SimpleForm {...props} toolbar={<CustomToolbar />} validate={validateDateCreation}>
        <Grid container spacing={1} style={{ width: "100%" }}>
          <Grid item xs={6}>
            <Labeled label="Current status">
              <TextField source="status.name" fullWidth />
            </Labeled>
            <Labeled label="Possible transitions">
              <TextField source="status.description" fullWidth />
            </Labeled>
          </Grid>
          {statusesQuery?.data ? (
            <Grid item xs={6}>
              <SelectInput
                source="status_id"
                fullWidth
                allowEmpty={true}
                choices={statusesQuery.data}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextInput source="title" fullWidth validate={required()} />
          </Grid>

          <Grid item xs={12}>
            <Field name="body" validate={requiredBody}>
              {(fieldProps) => {
                return (
                  <div>
                    <Editor {...fieldProps.input} record={props.record.body} />
                  </div>
                );
              }}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <ImageInput source="cover" label="Cover">
              <CustomImageField source="link"></CustomImageField>
            </ImageInput>
          </Grid>
          <Grid item xs={12}>
            <ReferenceInput
              source="opportunity_type_id"
              label = "Call types"
              reference="classifiers-opportunity-types"
            >
              <SelectInput optionText="name" fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12}>
            <ReferenceInput
              source="award_type_id"
              reference="classifiers-award-types"
            >
              <SelectInput optionText="name" fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12}>
            <NumberInput source="budget" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <ReferenceArrayInput
              source="interest_areas"
              reference="classifiers-interest-areas"
            >
              <SelectArrayInput optionText="name" fullWidth />
            </ReferenceArrayInput>
          </Grid>

          <Grid item xs={12}>
            <ReferenceArrayInput
              source="opportunity_tags"
              label = "Call tags"
              reference="classifiers-opportunity-tags"
            >
              <SelectArrayInput
                optionText="name"
                fullWidth
                translateChoice={false}
              />
            </ReferenceArrayInput>
          </Grid>
          <Grid item xs={12}>
            <ReferenceArrayInput
              source="creative_fields"
              reference="classifiers-creative-fields"
            >
              <SelectArrayInput
                optionText="name"
                fullWidth
                translateChoice={false}
              />
            </ReferenceArrayInput>
          </Grid>
          <Grid item xs={12}>
            <DateInput source="start_date" fullWidth  validate={[required()]}/>
          </Grid>
          <Grid item xs={12}>
            <DateInput source="end_date" fullWidth  validate={[required()]} />
          </Grid>

          <Grid item xs={12}>
            <FileInput
              multiple
              fullWidth
              source="files"
              label="Files"
              //accept="application/pdf"
            >
              <CustomFileField multiple source="link" />
            </FileInput>
          </Grid>
          <Grid item xs={12}>
            <ReferenceInput
                label="Call's creator"
                source="business_owner_id"
                reference="users"
            >
              <SelectInput
                  optionText="name"
                  fullWidth
                  validate={required()}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12}>
              <DateInput
                  label="Created"
                  source="created_at"
                  sortable={false}
                  locales="fr-FR"
                  fullWidth
                  disabled={true}
              />
          </Grid>
        </Grid>
      </SimpleForm>
    </React.Fragment>
  );
};
