import * as React from "react";
import { useState } from "react";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { green } from "@material-ui/core/colors";

import {
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    Datagrid,
    DateField,
    FunctionField,
    FileInput,
    ListContextProvider,
    useQueryWithStore,
    Loading,
    useDataProvider,
    useVersion,
    useRefresh,
    Pagination,
    Confirm,
    EditButton,
    DeleteButton,
    TextField, ReferenceInput, SelectInput,ReferenceField
} from "react-admin";
import keyBy from "lodash/keyBy";
import CustomFileField from "../../../components/CustomFileField";
import CustomDataGridFileField from "../../../components/CustomDataGridFileField";
import DataGridCustomActions from "../../../components/DataGridCustomActions";
import CommentsExpand from "../../../components/CommentsExpand";
import { Link } from "react-router-dom";
import dataGridMakeStyles from "../../../components/MuiDataGridClasses";
import {Field} from "react-final-form";
import Editor from "../../../components/Editor";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    paperFullWidth: {
        overflowY: "visible",
    },
    dialogContentRoot: {
        overflowY: "visible",
    },
});

const PostComment = (props) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const version = useVersion();

    const classes = useStyles();
    const dataGridClasses = dataGridMakeStyles();

    const [open, setOpen] = useState(false);
    const [dialogTitleText, setDialogTitleText] = useState("");
    const [dialogButtonText, setDialogButtonText] = useState("");
    const [serviceToEdit, setServiceToEdit] = useState(null);
    const [dialogButtonActionPath, setDialogButtonActionPath] = useState(null);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [rowToDelete, setRowToDelete] = useState();

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const DialogContent = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }))(MuiDialogContent);

    const DialogAddServiceToolbar = (props) => {
        return (
            <Toolbar {...props}>
                <SaveButton
                    label={dialogButtonText}
                    onSave={async (data) => {
                        // get id from the object, since there is no input field on frontend for id
                        if (serviceToEdit) {
                            await dataProvider.update(dialogButtonActionPath, {
                                id: data.id,
                                data,
                            });
                        } else {
                            await dataProvider.create(dialogButtonActionPath, {
                                data,
                            });
                        }

                        setOpen(false);
                        setServiceToEdit(null);
                        setDialogButtonActionPath(null);
                        refresh();
                    }}
                    submitOnEnter={false}
                />
            </Toolbar>
        );
    };

    const { data, total, loading, error } = useQueryWithStore({
        type: "getList",
        resource: `/posts/${props.record.id}/comments`,
        payload: {
            //pagination: { page, perPage },
            //sort: { field: "id", order: "ASC" },
            //filter: {},
        },
    });

    if (loading) {
        return null;
    }
    if (error) {
        return <p>ERROR: {error}</p>;
    }
    const confirmDelete = async () => {
        await dataProvider.delete(dialogButtonActionPath, {
            id: rowToDelete,
        });
        refresh();
        setOpenConfirmation(false);
    };

    const requiredBody = (value) => (value ? undefined : "Body text required");

    return (
        <>
            <ListContextProvider
                key={version}
                value={{
                    resource: `/posts/${props.record.id}/comments`,
                    basePath: `/posts/${props.record.id}/comments`,
                    data: keyBy(data, "id"),
                    ids: data.map(({ id }) => id),
                    currentSort: { field: "id", order: "ASC" },
                    selectedIds: [],
                }}
            >
                <Button
                    onClick={() => {
                        setDialogButtonText("Save");
                        setDialogTitleText("Add comment");
                        setDialogButtonActionPath(
                            `/posts/${props.record.id}/comments`
                        );
                        setOpen(true);
                    }}
                >
                    <span style={{ color: green[500] }}>Add comment</span>
                </Button>

                <Datagrid  classes={dataGridClasses}>
                    <TextField label="Message" source="message" />
                    <Grid item xs={12}>
                        <ReferenceField label="Create by"  source="created_by.id" reference="users">
                            <TextField source="name" />
                        </ReferenceField>
                    </Grid>
                    <DateField
                        source="updated_at"
                        label="Updated"
                        sortable={false}
                        locales="fr-FR"
                    />
                    <DataGridCustomActions
                        label="Actions"
                        titleText="Update comment"
                        buttonText="Update"
                        sortable={false}
                        setServiceToEdit={setServiceToEdit}
                        setDialogButtonActionPath={setDialogButtonActionPath}
                        setRowToDelete={setRowToDelete}
                        setOpenConfirmation={setOpenConfirmation}
                        setDialogButtonText={setDialogButtonText}
                        setOpen={setOpen}
                        setDialogTitleText={setDialogTitleText}
                    />
                </Datagrid>

            </ListContextProvider>

            <Dialog
                onClose={() => {
                    setOpen(false);
                    setServiceToEdit(null);
                }}
                aria-labelledby="customized-dialog-title"
                open={open}
                classes={{ paperFullWidth: classes.paperFullWidth }}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={() => {
                        setOpen(false);
                        setServiceToEdit(null);
                    }}
                >
                    {dialogTitleText}
                </DialogTitle>
                <DialogContent
                    dividers
                    classes={{
                        root: classes.dialogContentRoot,
                    }}
                >
                    <SimpleForm
                        toolbar={<DialogAddServiceToolbar />}
                        initialValues={
                            serviceToEdit
                                ? {
                                    ...serviceToEdit,
                                    supplier_id: props.record.id ? props.record.id : null,
                                }
                                : {
                                    supplier_id: props.record.id ? props.record.id : null,
                                }
                        }
                    >
                        <Grid container spacing={1} style={{ width: "100%" }}>
                            <Grid item xs={12}>
                                <TextInput
                                    label="Message"
                                    source="message"
                                    validate={required()}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FileInput
                                    multiple
                                    fullWidth
                                    source="files"
                                    label="Files"
                                    //accept="application/pdf"
                                >
                                    <CustomFileField multiple source="files" />
                                </FileInput>
                            </Grid>
                        </Grid>
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Confirm
                isOpen={openConfirmation}
                title="Delete record"
                content="Are you sure you want to delete this record?"
                onConfirm={confirmDelete}
                onClose={() => setOpenConfirmation(false)}
            />
        </>
    );
};

export default PostComment;
