import VisitorIcon from "@material-ui/icons/People";
import ChevronRight from "@material-ui/icons/ChevronRight";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import PersonIcon from "@material-ui/icons/Person";
import HotelIcon from "@material-ui/icons/Hotel";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import MuseumIcon from "@material-ui/icons/Museum";
import FreeBreakfastIcon from "@material-ui/icons/FreeBreakfast";
import CommuteIcon from "@material-ui/icons/Commute";

export const menuItems = [
  {
    name: "Classifiers",
    icon: ChevronRight,
    children: [
      {
        name: "Award types",
        link: "/classifiers-award-types",
        icon: VisitorIcon,
      },
      {
        name: "Interest areas",
        link: "/classifiers-interest-areas",
        icon: VisitorIcon,
      },
      {
        name: "Call tags",
        link: "/classifiers-opportunity-tags",
        icon: VisitorIcon,
      },
      {
        name: "Call types",
        link: "/classifiers-opportunity-types",
        icon: VisitorIcon,
      },
      {
        name: "Call statuses",
        link: "/classifiers-opportunity-statuses",
        icon: VisitorIcon,
      },
      {
        name: "Skills",
        link: "/classifiers-skills",
        icon: VisitorIcon,
      },
      {
        name: "Call categories",
        link: "/classifiers-opportunity-categories",
        icon: VisitorIcon,
      },
      {
        name: "Call roles",
        link: "/classifiers-opportunity-roles",
        icon: VisitorIcon,
      },
      {
        name: "Creative fields",
        link: "/classifiers-creative-fields",
        icon: VisitorIcon,
      },
      {
        name: "Purchase types",
        link: "/classifiers-purchase-types",
        icon: VisitorIcon,
      },
      {
        name: "Team terms of profit",
        link: "/classifiers-team-terms-of-profit",
        icon: VisitorIcon,
      },
      {
        name: "Team visibility",
        link: "/classifiers-team-visibility",
        icon: VisitorIcon,
      },
      {
        name: "Team statuses",
        link: "/classifiers-team-statuses",
        icon: VisitorIcon,
      },
      {
        name: "Task statuses",
        link: "/classifiers-task-statuses",
        icon: VisitorIcon,
      },
      {
        name: "Countries",
        link: "/countries",
        icon: VisitorIcon,
      },
      {
        name: "FAQ sections",
        link: "/classifiers-faq-sections",
        icon: VisitorIcon,
      },
      {
        name: "Creation statuses",
        link: "/classifiers-creation-statuses",
        icon: VisitorIcon,
      },
    ],
  },
  {
    name: "Calls",
    icon: ChevronRight,
    children: [
      {
        name: "Calls",
        link: "/opportunities",
        icon: VisitorIcon,
      },
      {
        name: "Teams",
        link: "/admin-teams",
        icon: VisitorIcon,
      },
    ],
  },
  {
    name: "Creations",
    icon: ChevronRight,
    children: [
      {
        name: "Creations",
        link: "/creations",
        icon: VisitorIcon,
      },
    ],
  },
  {
    name: "Posts",
    icon: ChevronRight,
    children: [
      {
        name: "Posts",
        link: "/posts",
        icon: VisitorIcon,
      },
      {
        name: "Groups",
        link: "/groups",
        icon: VisitorIcon,
      },
    ],
  },
  {
    name: "Users",
    icon: ChevronRight,
    children: [
      {
        name: "Users",
        link: "/users",
        icon: VisitorIcon,
      },
    ],
  },
  {
    name: "FAQs",
    icon: ChevronRight,
    children: [
      {
        name: "FAQs",
        link: "/faqs",
        icon: VisitorIcon,
      },
    ],
  },
  {
    name: "Manifest entries",
    icon: ChevronRight,
    children: [
      {
        name: "Manifest entries",
        link: "/manifests",
        icon: VisitorIcon,
      },
    ],
  },
];
