import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { translate, DashboardMenuItem, MenuItemLink } from "react-admin";
import { menuItems } from "../menu";

import SubMenu from "./SubMenu";

class Menu extends Component {
  state = {
    Supplier: false,
    Products: false,
  };

  static propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object,
  };

  handleToggle = (menu) => {
    this.setState((state) => ({ [menu]: !state[menu] }));
  };

  render() {
    const { onMenuClick, open } = this.props;
    return (
      <div>
        {" "}
        <DashboardMenuItem onClick={onMenuClick} />
        {menuItems.map((m) => (
          <SubMenu
            key={m.name}
            handleToggle={() => this.handleToggle(m.name)}
            isOpen={this.state[m.name]}
            sidebarIsOpen={open}
            name={m.name}
            icon={<m.icon />}
          >
            {m.children.map((c) => (
              <MenuItemLink
                key={c.name}
                to={c.link}
                /*                                     primaryText={translate(c.name, {
                                        smart_count: 2,
                                    })} */
                primaryText={c.name}
                leftIcon={<c.icon />}
                onClick={onMenuClick}
              />
            ))}
          </SubMenu>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
  theme: state.theme,
  //locale: state.i18n.locale,
});

const enhance = compose(withRouter, connect(mapStateToProps, {}), translate);

export default enhance(Menu);
