import * as React from "react";
import {
  DeleteButton,
  NumberInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  TitleForRecord,
  Toolbar,
  ImageInput,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  FileInput,
  FileField,
  DateInput,
  useQuery,
  TextField,
  Labeled,
} from "react-admin";
import { Grid } from "@material-ui/core";

import CustomImageField from "../../../components/CustomImageField";
import CustomFileField from "../../../components/CustomFileField";
import { Field, FormSpy } from "react-final-form";
import Editor from "../../../components/Editor";
import moment from "moment";
import TeamRoles from "../../../components/TeamRoles";
import Typography from "@material-ui/core/Typography";
import {useState} from "react";

export default (props) => {
  console.log("props", props);

  const [serviceToEdit, setServiceToEdit] = useState(null);

  const CustomToolbar = (props) => {
    return (
        <Toolbar
            {...props}
            style={{ display: "flex", justifyContent: "space-between" }}
        >
          <SaveButton
              disabled={props.pristine}
              transform={(data) => {
                delete data["opportunity_roles"];
                return data;
              }}
          />
          <DeleteButton undoable={false} />
        </Toolbar>
    );
  };
  return (
    <React.Fragment>
      <TitleForRecord
        //title={props.record.name}
        record={props.record}
        defaultTitle={props.defaultTitle}
      />
      <SimpleForm {...props} toolbar={<CustomToolbar />}>
        <Grid container spacing={1} style={{ width: "100%" }}>
          <Grid item xs={12}>
            <TextInput label="Name" source="name" fullWidth validate={required()} />
          </Grid>
          <Grid item xs={12}>
            <TextInput label="Description" source="description" fullWidth validate={required()} />
          </Grid>
          <Grid item xs={12}>
            <ReferenceInput
                source="opportunity_id"
                reference="opportunities"
                label="Calls"
            >
              <SelectInput
                  optionText="title"
                  fullWidth
                  validate={required()}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12}>
            <ReferenceInput
                source="terms_of_profit_id"
                reference="classifiers-team-terms-of-profit"
            >
              <SelectInput
                  optionText="name"
                  fullWidth
                  validate={required()}
              />
            </ReferenceInput>
          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*  <ReferenceInput*/}
          {/*      source="visibility_id"*/}
          {/*      reference="classifiers-team-visibility"*/}
          {/*  >*/}
          {/*    <SelectInput*/}
          {/*        optionText="name"*/}
          {/*        fullWidth*/}
          {/*        validate={required()}*/}
          {/*    />*/}
          {/*  </ReferenceInput>*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <ReferenceInput
                label="Team's creator"
                source="master_creator_id"
                reference="users"
            >
              <SelectInput
                  optionText="name"
                  fullWidth
                  validate={required()}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <Labeled label="Current status">
              <TextField source="status.name" fullWidth />
            </Labeled>
            <Labeled label="Possible transitions">
              <TextField source="status.description" fullWidth />
            </Labeled>
          </Grid>
          <Grid item xs={6}>
          <ReferenceInput
              label="Status"
              source="status_id"
              reference="classifiers-team-statuses"
              alwaysOn
          >
            <SelectInput label="Status" source="status_id" optionText="name" />
          </ReferenceInput>
          </Grid>
          <Grid item xs={12}>
            <SelectInput label="Reward" source="reward" choices={[
              { id: 'Winner', name: 'Winner' },
              { id: 'Karma', name: 'Karma' },
            ]} />
          </Grid>
        </Grid>
      </SimpleForm>
    </React.Fragment>
  );
};
