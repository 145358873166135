import * as React from "react";
import { Admin, Layout, Notification, Resource } from "react-admin";
import authProvider from "./providers/auth/jwt";
import dataProvider from "./providers/data/rest";
import Menu from "./components/Menu";
import Dashboard from "./components/Dashboard";

import opportunities from "./resources/opportunities";
import interestAreas from "./resources/classifiers/interest-areas";
import awardTypes from "./resources/classifiers/award-types";
import opportunityTypes from "./resources/classifiers/opportunity-types";
import opportunityTags from "./resources/classifiers/opportunity-tags";
import creationTags from "./resources/classifiers/creation-tags";
import skills from "./resources/classifiers/skills";
import users from "./resources/users";
import creations from "./resources/creations";
import opportunityCategories from "./resources/classifiers/opportunity-categories";
import opportunityRoles from "./resources/classifiers/opportunity-roles";
import creativeFields from "./resources/classifiers/creative-fields";
import purchaseTypes from "./resources/classifiers/purchase-types";
import teamTermsOfProfit from "./resources/classifiers/team-terms-of-profit";
import teamVisibility from "./resources/classifiers/team-visibility";
import taskStatuses from "./resources/classifiers/task-statuses";
import opportunityStatuses from "./resources/classifiers/opportunity-statuses";
import creationStatuses from "./resources/classifiers/creation-statuses";
import teamStatuses from "./resources/classifiers/team-statuses";
import countries from "./resources/classifiers/countries";
import faqSections from "./resources/classifiers/faq-sections";
import faqs from "./resources/faqs";
import manifestEntries from "./resources/manifest-entries";
import http from "./http";
import adminTeams from "./resources/admin-teams";
import posts from "./resources/posts";
import groups from "./resources/groups";

import "devextreme/dist/css/dx.common.css";
//import 'devextreme/dist/css/dx.light.css';
import "devextreme/dist/css/dx.material.lime.light.css";
import {Title} from "devextreme-react/bar-gauge";

const MyNotification = () => {
  return <Notification style={{}} />;
};

const MyLayout = (props) => (
  <Layout {...props} menu={Menu} notification={MyNotification} />
);

const App = () => (
  <Admin
    title="Admin console - WeCreators"
    //customRoutes={customRoutes}
    dataProvider={dataProvider(http)}
    authProvider={authProvider(http)}
    dashboard={Dashboard}
    layout={MyLayout}
  >
    <Resource name="classifiers-award-types" {...awardTypes} />
    <Resource name="classifiers-interest-areas" {...interestAreas} />
    <Resource name="classifiers-opportunity-types" {...opportunityTypes} />
    <Resource name="classifiers-opportunity-tags" {...opportunityTags} />
    <Resource name="classifiers-creation-tags" {...creationTags} />
    <Resource name="classifiers-opportunity-categories" {...opportunityCategories} />
    <Resource name="classifiers-opportunity-statuses" {...opportunityStatuses} />
    <Resource name="classifiers-opportunity-roles" {...opportunityRoles} />
    <Resource name="classifiers-skills" {...skills} />
    <Resource name="classifiers-creative-fields" {...creativeFields} />
    <Resource name="classifiers-purchase-types" {...purchaseTypes} />
    <Resource name="classifiers-team-terms-of-profit" {...teamTermsOfProfit} />
    <Resource name="classifiers-team-visibility" {...teamVisibility} />
    <Resource name="classifiers-task-statuses" {...taskStatuses} />
    <Resource name="opportunities" {...opportunities} />
    <Resource name="admin-teams" {...adminTeams} />
    <Resource name="users" {...users} />
    <Resource name="creations" {...creations} />
    <Resource name="countries" {...countries} />
    <Resource name="classifiers-faq-sections" {...faqSections} />
    <Resource name="faqs" {...faqs} />
    <Resource name="manifests" {...manifestEntries} />
    <Resource name="opportunity-statuses" />
    <Resource name="creation-statuses"  />
    <Resource name="classifiers-creation-statuses" {...creationStatuses} />
    <Resource name="classifiers-profile-types" />
    <Resource name="posts" {...posts} />
    <Resource name="groups" {...groups} />
    <Resource name="classifiers-team-statuses" {...teamStatuses} />

  </Admin>
);
export default App;
