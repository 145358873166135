import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  Filter,
  ReferenceField,
  ReferenceArrayField,
  TextInput,
  SingleFieldList,
  ChipField,
  NumberField
} from "react-admin";

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
  </Filter>
);

export default (props) => {
  return (
    <List {...props} title="Groups" filters={<PostFilter />}>
      <Datagrid>
        <TextField source="name" />
        <ReferenceArrayField
          label="Interest areas"
          source="interest_areas"
          reference="classifiers-interest-areas"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};
