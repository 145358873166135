import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  Filter,
  TextInput,
} from "react-admin";

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
  </Filter>
);

export default (props) => {
  return (
    <List
      {...props}
      title="Skills"
      filters={<PostFilter />}
      rowsPerPageOptions={[10, 25, 50, 100]}
      perPage={25}
    >
      <Datagrid>
        <TextField source="name" />
        <TextField source="description" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};
