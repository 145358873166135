import * as React from "react";
import { TopToolbar, ListButton } from "react-admin";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

export const PostEditActions = (props) => {
  let basePath = props.location.state
    ? `${props.basePath}?filter=${JSON.stringify(props.location.state.filters)}`
    : props.basePath;
  return (
    <TopToolbar>
      <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
    </TopToolbar>
  );
};
