import * as React from "react";
import { useState } from "react";
import {
  BooleanInput,
  DeleteButton,
  NumberInput,
  required,
  number,
  regex,
  minValue,
  SaveButton,
  SimpleForm,
  TextInput,
  TitleForRecord,
  Toolbar,
  ReferenceArrayInput,
  SelectArrayInput,
  ImageInput,
  PasswordInput,
  ArrayInput,
  SimpleFormIterator,
  FileInput,
  ReferenceInput,
  SelectInput,

} from "react-admin";

import CustomImageField from "../../components/CustomImageField";
import CustomFileField from "../../components/CustomFileField";
import Editor from "../../components/Editor";
import { Grid } from "@material-ui/core";
import { Field, FormSpy } from "react-final-form";
import TeamRoles from "../../components/TeamRoles";
import Typography from "@material-ui/core/Typography";
import GeneralInfoTab from "./tabs/GeneralInfo";
import MemberTab from "./tabs/MemberTab";
import StagesTab from "./tabs/Stages";
import TasksTab from "./tabs/Tasks";
import SettingsTab from "./tabs/Settings";
import Tab from "@material-ui/core/Tab";
import {TabPanel} from "@material-ui/lab";
import TabList from "@material-ui/lab/TabList";
import TabContext from "@material-ui/lab/TabContext";


export default (props) => {


  const [activeTab, setActiveTab] = React.useState("General");

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const mainTab = {
    name: "General",
    component: <GeneralInfoTab {...props} />,
  };
  const tabs = [
    {
      name: "Member",
      component: <MemberTab {...props} />,
    },
    {
      name: "Stages",
      component: <StagesTab {...props} />,
    },
    {
      name: "Tasks",
      component: <TasksTab {...props} />,
    },
    {
      name: "Settings",
      component: <SettingsTab {...props} />,
    }
  ];

  // material ui bug prevents conditional rendering inside tablist
  const tabListTabs = props.record.id ? (
      tabs.map((t) => <Tab label={t.name} value={t.name} />)
  ) : (
      <Tab label="" value="" disabled />
  );
  const tabPanelTabs = props.record.id ? (
      tabs.map((t) => (
          <TabPanel label={t.name} value={t.name}>
            {t.component}
          </TabPanel>
      ))
  ) : (
      <TabPanel label="" value="">
        {null}
      </TabPanel>
  );


  return (
    <React.Fragment>
      <TitleForRecord
        title={props.record.name}
        record={props.record}
        defaultTitle={props.defaultTitle}
      />
      <TabContext value={activeTab}>
        <TabList onChange={handleChange} aria-label="Teams">
          <Tab label={mainTab.name} value={mainTab.name} />
          {tabListTabs}
        </TabList>
        <TabPanel label={mainTab.name} value={mainTab.name}>
          {mainTab.component}
        </TabPanel>
        {tabPanelTabs}
      </TabContext>

    </React.Fragment>
  );
};
