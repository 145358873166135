import * as React from "react";
import { useState } from "react";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { green } from "@material-ui/core/colors";

import {
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  Datagrid,
  DateField,
  FunctionField,
  FileInput,
  ListContextProvider,
  useQueryWithStore,
  Loading,
  useDataProvider,
  useVersion,
  useRefresh,
  Pagination,
  Confirm,
  EditButton,
  DeleteButton,
  TextField,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import keyBy from "lodash/keyBy";
import CustomFileField from "../../../components/CustomFileField";
import CustomDataGridFileField from "../../../components/CustomDataGridFileField";
import DataGridCustomActions from "../../../components/DataGridCustomActions";
import CommentsExpand from "../../../components/CommentsExpand";
import { Link } from "react-router-dom";
import dataGridMakeStyles from "../../../components/MuiDataGridClasses";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
});

const Tasks = (props) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const version = useVersion();

  const classes = useStyles();
  const dataGridClasses = dataGridMakeStyles();

  const [open, setOpen] = useState(false);
  const [dialogTitleText, setDialogTitleText] = useState("");
  const [dialogButtonText, setDialogButtonText] = useState("");
  const [serviceToEdit, setServiceToEdit] = useState(null);
  const [dialogButtonActionPath, setDialogButtonActionPath] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [rowToDelete, setRowToDelete] = useState();
  const [stages, setStages] = useState([]);

  React.useEffect(() => {
    async function fetchStages(reference, filter) {
      const response = await dataProvider.getList(reference, {
        filter,
      });
      setStages(response.data);
    }

    fetchStages(`/opportunities/${props.record.id}/stages`, null);
  }, []);

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogAddServiceToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton
          label={dialogButtonText}
          onSave={async (data) => {
            // get id from the object, since there is no input field on frontend for id
            if (serviceToEdit) {
              await dataProvider.update(dialogButtonActionPath, {
                id: data.id,
                data,
              });
            } else {
              await dataProvider.create(dialogButtonActionPath, {
                data,
              });
            }

            setOpen(false);
            setServiceToEdit(null);
            setDialogButtonActionPath(null);
            refresh();
          }}
          submitOnEnter={false}
        />
      </Toolbar>
    );
  };

  const { data, total, loading, error } = useQueryWithStore({
    type: "getList",
    resource: `/opportunities/${props.record.id}/tasks`,
    payload: {
      //pagination: { page, perPage },
      sort: { field: "id", order: "ASC" },
      //filter: {},
    },
  });

  if (loading) {
    return null;
  }
  if (error) {
    return <p>ERROR: {error}</p>;
  }
  const confirmDelete = async () => {
    await dataProvider.delete(dialogButtonActionPath, {
      id: rowToDelete,
    });
    refresh();
    setOpenConfirmation(false);
  };

  return (
    <>
      <ListContextProvider
        key={version}
        value={{
          resource: `/opportunities/${props.record.id}/tasks`,
          basePath: `/opportunities/${props.record.id}/tasks`,
          data: keyBy(data, "id"),
          ids: data.map(({ id }) => id),
          currentSort: { field: "id", order: "ASC" },
          selectedIds: [],
        }}
      >
        <Button
          onClick={() => {
            setDialogButtonText("Save");
            setDialogTitleText("Add task");
            setDialogButtonActionPath(
              `/opportunities/${props.record.id}/tasks`
            );
            setOpen(true);
          }}
        >
          <span style={{ color: green[500] }}>Add task</span>
        </Button>

        <Datagrid expand={<CommentsExpand />} classes={dataGridClasses}>
          <TextField label="Name" source="name" />
          <TextField label="Description" source="description" />
          <TextField label="Status" source="status.name" />
          <TextField label="Stage" source="stage.name" />
          <TextField label="Assignee" source="assignee.name" />
          <CustomDataGridFileField
            source="files"
            label="Files"
            sortable={false}
          />
          <DateField
            source="updated_at"
            label="Updated"
            sortable={false}
            locales="fr-FR"
          />
          <DataGridCustomActions
            label="Actions"
            titleText="Update task"
            buttonText="Update"
            sortable={false}
            setServiceToEdit={setServiceToEdit}
            setDialogButtonActionPath={setDialogButtonActionPath}
            setRowToDelete={setRowToDelete}
            setOpenConfirmation={setOpenConfirmation}
            setDialogButtonText={setDialogButtonText}
            setOpen={setOpen}
            setDialogTitleText={setDialogTitleText}
          />
        </Datagrid>
        {/*         <Pagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          page={page}
          perPage={perPage}
          setPage={setPage}
          total={total}
        /> */}
      </ListContextProvider>

      <Dialog
        onClose={() => {
          setOpen(false);
          setServiceToEdit(null);
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{ paperFullWidth: classes.paperFullWidth }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpen(false);
            setServiceToEdit(null);
          }}
        >
          {dialogTitleText}
        </DialogTitle>
        <DialogContent
          dividers
          classes={{
            root: classes.dialogContentRoot,
          }}
        >
          <SimpleForm
            toolbar={<DialogAddServiceToolbar />}
            initialValues={
              serviceToEdit
                ? {
                    ...serviceToEdit,
                    supplier_id: props.record.id ? props.record.id : null,
                  }
                : {
                    supplier_id: props.record.id ? props.record.id : null,
                  }
            }
          >
            <Grid container spacing={1} style={{ width: "100%" }}>
              <Grid item xs={12}>
                <TextInput
                  label="Name"
                  source="name"
                  validate={required()}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  multiline
                  label="Description"
                  source="description"
                  validate={required()}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <SelectInput
                  allowEmpty
                  choices={stages}
                  source="stage_id"
                  optionText="title"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <ReferenceInput
                  source="status_id"
                  reference="classifiers-task-statuses"
                >
                  <SelectInput optionText="name" fullWidth />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12}>
                <ReferenceInput source="assignee_id" reference="users">
                  <SelectInput optionText="name" fullWidth />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12}>
                <FileInput
                  multiple
                  fullWidth
                  source="files"
                  label="Files"
                  //accept="application/pdf"
                >
                  <CustomFileField multiple source="url" />
                </FileInput>
              </Grid>
            </Grid>
          </SimpleForm>
        </DialogContent>
      </Dialog>
      <Confirm
        isOpen={openConfirmation}
        title="Delete record"
        content="Are you sure you want to delete this record?"
        onConfirm={confirmDelete}
        onClose={() => setOpenConfirmation(false)}
      />
    </>
  );
};

export default Tasks;
